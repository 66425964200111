import angular from "angular";
import routerApp from "../app.module";
import { TankStock } from "../models/TankStock";

routerApp.factory('BdnVesselReportService', function () { 
   const getTanksUsingBdn = function(bdn, scope) {
                if (!bdn) return []

                const tanksUsingBdn = []
                for (let tankId in scope.report.stock.tanks) {
                    const tank = scope.report.stock.tanks[tankId]
                    if (tank?.bdn_added_in_tank?.bdn_number === bdn) {
                        tanksUsingBdn.push([tankId, tank])
                    }
                }

                return tanksUsingBdn
            }

  const updateBdnBlend = function (tankId, tank, scope) {
                  const stockTank = scope.report.stock.tanks[tankId]
                  if (!tank.bdn_added_in_tank && !stockTank.bdn_added_in_tank) {
                      tank.new_bdn = undefined
                      tank.sulphur_after_bunkering = tank.sulphur_before_bunkering
                      return
                  }
                 const bdn_added_in_tank = tank.bdn_added_in_tank ? tank.bdn_added_in_tank : stockTank.bdn_added_in_tank
                  const quantity_added_in_tank = tank.quantity_added_in_tank ? tank.quantity_added_in_tank : stockTank.quantity_added_in_tank
                  
                  const newTankStock = TankStock.createChildBdn(
                      bdn_added_in_tank,
                      tank,
                      quantity_added_in_tank,
                      true,
                      Object.keys(scope.availableFuelGrades)
                  )
                  
                  const totalFuel = tank.amount_before_bunkering + quantity_added_in_tank
                  const bdn1SulphurRatio = tank.sulphur_before_bunkering * (tank.amount_before_bunkering / totalFuel)
                  const bdn2SulphurRatio = bdn_added_in_tank.sulphur_before_bunkering * (quantity_added_in_tank / totalFuel)
                  stockTank.sulphur_after_bunkering = bdn1SulphurRatio + bdn2SulphurRatio
                  stockTank.new_bdn = newTankStock
                  stockTank.is_blend = true
  }

  const bdnQuantityNAN = (quantity, tankId, scope) => {
    if (isNaN(quantity)) {
      scope.$parent.reportForm.bunker.tankReporting.$setValidity(`nan${tankId}`, false);
      scope.bunkerValidationMessages[`nan${tankId}`] = "Quantity must be a number"
    }
  }

  const bdnQuantityLessThanZero = (quantity, tankId, scope) => {
    if(isNaN(quantity)) return
    if (quantity < 0) {
      scope.$parent.reportForm.bunker.tankReporting.$setValidity(`underCapacity${tankId}`, false);
      scope.bunkerValidationMessages[`underCapacity${tankId}`] = "Can't add a negative quantity"
    }
  }

  const bdnQuantityZeroOrGreater = (tank, scope, tankId) => {
    if(isNaN(tank.quantity_added_in_tank)) return
    if (tank.quantity_added_in_tank < 0) return
    
    const tanks = getTanksUsingBdn(tank.bdn_added_in_tank?.bdn_number, scope)
    const added = tanks.map((t) => t[1].quantity_added_in_tank).reduce((sum, a) => sum + a, 0)
    if (added > tank.bdn_added_in_tank?.quantity_received) {
        scope.$parent.reportForm.bunker.tankReporting.$setValidity(`insufficientFuel${tankId}`, false);
        scope.bunkerValidationMessages[`insufficientFuel${tankId}`] = 'Insufficient fuel'
    }
    if (tank.amount_before_bunkering + tank.quantity_added_in_tank > tank.capacity98MT) {
        scope.$parent.reportForm.bunker.tankReporting.$setValidity(`overCapacity${tankId}`, false);
        scope.bunkerValidationMessages[`overCapacity${tankId}`] = 'Tank over capacity'
    }
  }
    
const validateBunkerTankBdn = function(tankId, tank, scope) {
  tank = tank ? tank : scope.report.stock.tanks[tankId]
    
  if (!tank) return

  if (!scope.bunkerValidationMessages) {
      scope.bunkerValidationMessages = {}
  }

  updateBdnBlend(tankId, tank, scope)

  scope.bunkerValidationMessages[`nan${tankId}`] = null
  scope.bunkerValidationMessages[`underCapacity${tankId}`] = null
  scope.bunkerValidationMessages[`insufficientFuel${tankId}`] = null
  scope.bunkerValidationMessages[`overCapacity${tankId}`] = null
  scope.$parent.reportForm.bunker.tankReporting.$setValidity(`underCapacity${tankId}`, true);
  scope.$parent.reportForm.bunker.tankReporting.$setValidity(`overCapacity${tankId}`, true);
  scope.$parent.reportForm.bunker.tankReporting.$setValidity(`insufficientFuel${tankId}`, true);
  scope.$parent.reportForm.bunker.tankReporting.$setValidity(`nan${tankId}`, true);

  if (!tank.bdn_added_in_tank)  return
    bdnQuantityNAN(tank.quantity_added_in_tank, tankId, scope)
    bdnQuantityLessThanZero(tank.quantity_added_in_tank, tankId, scope)
    bdnQuantityZeroOrGreater(tank, scope, tankId)
                
}
  const getTransferCorrection = (fuelGrade: string, scope: any): number => {
    const corrections = scope.tank_transfers_corrections
    if (!corrections[fuelGrade]) return 0
    
    return corrections[fuelGrade].reduce((acc, curr) => acc + curr, 0)
  }

  const createCorrections = (scope, preTransferStock) => {
    const corrections = {}
    const tank_transfers = scope.report.stock.tank_transfers
    angular.forEach(tank_transfers, function (tankTransfer) { 
      const toId = tankTransfer.to_tank

      if (!tankTransfer.bdn?.amount) return
      if (preTransferStock[toId]?.fuel_grade === tankTransfer.bdn?.fuel_grade) return
      
      if(!corrections[preTransferStock[toId].fuel_grade]) corrections[preTransferStock[toId].fuel_grade] = []
      corrections[preTransferStock[toId].fuel_grade] = [...corrections[preTransferStock[toId].fuel_grade], ...[(tankTransfer.quantity * -1)]]

      if(!corrections[tankTransfer.bdn.fuel_grade]) corrections[tankTransfer.bdn.fuel_grade] = []
      corrections[tankTransfer.bdn.fuel_grade] = [...corrections[tankTransfer.bdn.fuel_grade], ...[tankTransfer.quantity]]
        
      })
    return corrections
  }

return {
  validateBunkerTankBdn,
  getTransferCorrection,
  createCorrections
  } 
})